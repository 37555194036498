import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  ViewChild,
} from '@angular/core';
import { gql } from '@apollo/client';
import { User } from '../../core/state/user/csd-user.types';
import { CsdCurrentPageService } from '../../core/services/csd-current-page.service';
import { CsdBiService } from '../../features/csd-bi/csd-bi.service';
import { CsdDataService } from '../../core/services/csd-data.service';
import { PAGE_KEY_DASHBOARD } from '../../core/constants/page.constants';
import { BiEvent } from '../../features/csd-bi/csd-bi.types';
import { map, tap } from 'rxjs/operators';
import {
  PATH_CREATOR_PROJECT_LIST,
  PATH_PLAN_SELECTION,
} from '../../core/constants/router.constants';
import { CplGenerateProjectDialogComponent } from '../cpl-generate-project-dialog/cpl-generate-project-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CplTutorialDialogComponent } from '../cpl-tutorial-dialog/cpl-tutorial-dialog.component';
import { CsdConfirmDialogService } from '../../core/csd-confirm-dialog/csd-confirm-dialog.service';
import { CsdRouterUtilsService } from '../../core/csd-router-utils.service';

enum TourStepKey {
  CREATED_PROJECT = 'CREATED_PROJECT',
  COMPLETED_TUTORIAL = 'COMPLETED_TUTORIAL',
  GOT_HELP = 'GOT_HELP',
  MANAGE_PLANS = 'MANAGE_PLANS',
}

export const setTourStepsMutation = gql`
  mutation setTourSteps($input: SetTourStepsMutationInput!) {
    setTourSteps(input: $input) {
      clientMutationId
      error {
        id
        message
      }
      user {
        id
        tourSteps
      }
    }
  }
`;

@Component({
  selector: 'cpl-dashboard',
  templateUrl: './cpl-dashboard.component.html',
  styleUrls: ['./cpl-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CplDashboardComponent implements OnInit {
  showWelcomeMessage = false;
  tourSteps: { [key in TourStepKey]: string } | {} = {};
  tourStepKeyEnum = TourStepKey;
  user: User;
  PATH_CREATOR_CREATE_PROJECT = PATH_PLAN_SELECTION;
  initial = true;
  @ViewChild('createProjectStep') createProjectStep;

  constructor(
    private csdCurrentPageService: CsdCurrentPageService,
    private csdBiService: CsdBiService,
    private csdDataService: CsdDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private csdConfirmDialogService: CsdConfirmDialogService,
    private csdRouterUtilsService: CsdRouterUtilsService
  ) {}

  ngOnInit() {
    this.csdCurrentPageService.setCurrentPageKey$(PAGE_KEY_DASHBOARD);
    this.csdBiService.logEvent(BiEvent.VIEWED_DASHBOARD);
    const ME_QUERY = gql`
      query Me {
        me {
          id
          firstName
          email
          gitUserUrl
          tourSteps
        }
      }
    `;

    // TODO: get status of "showWelcomeMessage" from server
    this.showWelcomeMessage = true;

    this.csdDataService
      .typedQuery<User, any>(ME_QUERY, {}, ['data', 'me'])
      .pipe(
        tap((user: any) => {
          this.user = user;
          this.tourSteps = JSON.parse(user.tourSteps);

          if (this.initial && !this.getTourStepValue(TourStepKey.CREATED_PROJECT)) {
            this.createProjectStep.open();
            this.initial = false;
          }

          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe();
  }

  closeWelcomeMessage() {
    //TODO: update as well on server
    this.showWelcomeMessage = false;
  }

  percentTour(): number {
    const totalTourSteps = 5;
    return (
      (100 / totalTourSteps) *
      (Object.keys(this.tourSteps).filter((key) => this.tourSteps[key] === true).length + 1)
    );
  }

  setTourStep(tourStep: TourStepKey, value: boolean) {
    this.tourSteps[tourStep] = value;

    //persist on server
    this.csdDataService
      .mutate$<any>(setTourStepsMutation, { input: { tourSteps: JSON.stringify(this.tourSteps) } })
      .subscribe();
  }

  toggleTourStep(tourStep: TourStepKey) {
    this.setTourStep(tourStep, !this.getTourStepValue(tourStep));
  }

  checkClicked(event: any, tourStep: TourStepKey) {
    this.toggleTourStep(tourStep);
    event.stopPropagation();
    this.changeDetectorRef.markForCheck();
  }

  getTourStepValue(tourStep: TourStepKey): boolean {
    if (tourStep in this.tourSteps) {
      return this.tourSteps[tourStep];
    } else {
      return false;
    }
  }

  showTutorialDialog() {
    const dialogRef = this.matDialog.open(CplTutorialDialogComponent, {
      width: '700px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The Tutorial dialog was closed');
    });
  }

  goToGit() {
    this.csdConfirmDialogService
      .openConfirmDialog({
        title: 'Git Credentials',
        cancelButtonTitle: 'Cancel',
        submitButtonTitle: 'Go to Git',
        messageHtml: `
        To login to git.cnc.io use the same credentials as in the seed console.<br><br>

        email: <b>${this.user.email}</b><br>
        password: <b>&lt;same password as here&gt;</b>
        `,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          //window.location.href = 'https://git.cnc.io';
          window.open('https://git.cnc.io/cnc-org/demo-todo-app', '_blank');
        } else {
          //canceled
        }
      });
  }
}

@Component({
  selector: 'cpl-check-icon',
  template: `
    <img *ngIf="_checked" class="cpl-icon" src="assets/images/icons/accordion-check.svg" />
    <img *ngIf="!_checked" class="cpl-icon" src="assets/images/icons/accordion-not-checked.svg" />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CplCheckIconComponent {
  _checked: boolean;
  @Input() set checked(value: boolean) {
    this._checked = value;
  }
  get checked() {
    return;
  }
}
