import { DeploymentEnvironment } from '../app/core/core.types';
import { CSD_MAIN_VERSION } from './generated-environment';
import { CSD_PROJECT_VERSION, CSD_BUILD_TIME } from './project-version';

export const defaultEnvironment: DeploymentEnvironment = {
  production: false,
  mainVersion: CSD_MAIN_VERSION,
  projectVersion: CSD_PROJECT_VERSION,
  buildTime: CSD_BUILD_TIME,
  enableGoogleAnalytics: false,
};
