import { NgModule } from '@angular/core';
import { CsdBillingInfoComponent } from './csd-billing-info/csd-billing-info.component';
import { SharedModule } from '../../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  imports: [SharedModule, MatIconModule, MatAutocompleteModule],
  declarations: [CsdBillingInfoComponent],
  exports: [CsdBillingInfoComponent],
})
export class CsdBillingInfoModule {}
