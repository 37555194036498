<div class="csd-request-password-reset">
  <div *ngIf="!resetDone" class="csd-form-step">
    <h1 class="csd-title">{{ 'USER_REQUEST_PASSWORD_RESET.TITLE' | i18next }}</h1>
    <p
      class="csd-description"
      [innerHtml]="'USER_REQUEST_PASSWORD_RESET.DESCRIPTION' | i18next"
    ></p>
    <form [formGroup]="resetRequestForm" (ngSubmit)="onSubmit()" novalidate>
      <mat-form-field [appearance]="formFieldAppearance">
        <mat-label>{{ 'USER_REQUEST_PASSWORD_RESET.FORM.EMAIL.LABEL' | i18next }}</mat-label>
        <input
          matInput
          [placeholder]="'USER_REQUEST_PASSWORD_RESET.FORM.EMAIL.PLACEHOLDER' | i18next"
          formControlName="email"
          autocomplete="email"
        />
        <mat-hint>{{ 'USER_REQUEST_PASSWORD_RESET.FORM.EMAIL.HINT' | i18next }}</mat-hint>
        <mat-error *ngIf="(emailForm.dirty || emailForm.touched) && emailForm.errors?.required">
          {{ 'USER_REQUEST_PASSWORD_RESET.FORM.EMAIL.ERROR.REQUIRED' | i18next }}
        </mat-error>
        <mat-error
          *ngIf="
            (emailForm.dirty || emailForm.touched) &&
            emailForm.errors?.invalid &&
            !emailForm.errors?.required
          "
        >
          {{ 'USER_REQUEST_PASSWORD_RESET.FORM.EMAIL.ERROR.INVALID' | i18next }}
        </mat-error>
      </mat-form-field>

      <button
        class="csd-request-reset-button"
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="inProgress"
      >
        <mat-spinner *ngIf="inProgress" class="csd-button-spinner"></mat-spinner>
        {{ 'USER_REQUEST_PASSWORD_RESET.FORM.REQUEST_RESET_BUTTON' | i18next }}
      </button>
    </form>
  </div>
  <div
    *ngIf="resetDone"
    class="csd-success-step"
    fxLayout="column"
    fxLayoutAlign="space-around stretch"
  >
    <h1 class="csd-title">
      {{ 'USER_REQUEST_PASSWORD_RESET.CONFIRM_EMAIL_SENT_TITLE' | i18next }}
    </h1>
    <p
      class="csd-description"
      [innerHtml]="
        'USER_REQUEST_PASSWORD_RESET.CONFIRM_EMAIL_SENT_INFO' | i18next: { email: email }
      "
    ></p>
    <button class="csd-back-to-login" color="primary" mat-flat-button [mat-dialog-close]="true">
      {{ 'USER_REQUEST_PASSWORD_RESET.BACK_TO_LOGIN_BUTTON' | i18next }}
    </button>
  </div>
</div>
