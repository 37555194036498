import { NgModule } from '@angular/core';

import { CplProjectListComponent } from './cpl-project-list/cpl-project-list.component';
import { CplProjectListPageComponent } from './cpl-project-list-page/cpl-project-list-page.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../shared/shared.module';
import { CplProjectEditPageComponent } from './cpl-project-edit-page/cpl-project-edit-page.component';
import { CplProjectEditComponent } from './cpl-project-edit/cpl-project-edit.component';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatStepperModule } from '@angular/material/stepper';
import { CplProjectConfigInputModule } from '../features/cpl-project-config-input/cpl-project-config-input.module';
import { CplGenerateProjectDialogComponent } from './cpl-generate-project-dialog/cpl-generate-project-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CplUpdateInfoComponent } from './cpl-update-info/cpl-update-info.component';
import { MatCardModule } from '@angular/material/card';
import { CplProjectCreatePageComponent } from './cpl-project-create-page/cpl-project-create-page.component';
import { CplProjectCreateComponent } from './cpl-project-create/cpl-project-create.component';
import { CsdCardStepperModule } from '../features/csd-card-stepper/csd-card-stepper.module';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CplPlanSelectionComponent } from './cpl-plan-selection/cpl-plan-selection.component';
import { CplPlanSelectionPageComponent } from './cpl-plan-selection-page/cpl-plan-selection-page.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { CsdBillingInfoModule } from '../features/csd-billing-info/csd-billing-info.module';
import { CplPlanComponent } from './cpl-plan/cpl-plan.component';
import { CplDashboardPageComponent } from './cpl-dashboard-page/cpl-dashboard-page.component';
import {
  CplCheckIconComponent,
  CplDashboardComponent,
} from './cpl-dashboard/cpl-dashboard.component';
import { CplTutorialDialogComponent } from './cpl-tutorial-dialog/cpl-tutorial-dialog.component';
import { CplProjectHelpDialogComponent } from './cpl-project-help-dialog/cpl-project-help-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  imports: [
    SharedModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatStepperModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatTabsModule,
    RouterModule,
    CplProjectConfigInputModule,
    MatCardModule,
    CsdCardStepperModule,
    CdkStepperModule,
    CsdBillingInfoModule,
  ],
  declarations: [
    CplProjectListComponent,
    CplProjectListPageComponent,
    CplProjectEditPageComponent,
    CplProjectEditComponent,
    CplGenerateProjectDialogComponent,
    CplUpdateInfoComponent,
    CplProjectCreatePageComponent,
    CplProjectCreateComponent,
    CplPlanSelectionComponent,
    CplPlanSelectionPageComponent,
    CplPlanComponent,
    CplDashboardPageComponent,
    CplDashboardComponent,
    CplCheckIconComponent,
    CplTutorialDialogComponent,
    CplProjectHelpDialogComponent,
  ],
  entryComponents: [
    CplGenerateProjectDialogComponent,
    CplTutorialDialogComponent,
    CplProjectHelpDialogComponent,
  ],
  exports: [CplUpdateInfoComponent, CplDashboardPageComponent],
})
export class CreatorModule {}
