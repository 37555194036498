export const PAGE_KEY_DASHBOARD = 'DASHBOARD';
export const PAGE_KEY_ACCOUNT = 'ACCOUNT';
export const PAGE_KEY_USER_MANAGEMENT = 'USER_MANAGEMENT';
// 
export const PAGE_KEY_ORGANISATION_MANAGEMENT = 'ORGANISATION_MANAGEMENT';
export const PAGE_KEY_ANALYTICS = 'ANALYTICS';
export const PAGE_KEY_ADMIN_DASHBOARD = 'ADMIN_DASHBOARD';
export const PAGE_KEY_CONFIRM_DIALOG = 'CONFIRM_DIALOG';
export const PAGE_KEY_USER_INVITATION_LIST = 'USER_INVITATION_LIST';
export const PAGE_KEY_INVITATION_CHECK = 'INVITATION_CHECK';
