import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyModule } from '@ngx-formly/core';
import { ArrayTypeComponent } from './formly-utils/array.type';
import { ObjectTypeComponent } from './formly-utils/object.type';
import { MultiSchemaTypeComponent } from './formly-utils/multischema.type';
import { NullTypeComponent } from './formly-utils/null.type';
import {
  constValidationMessage,
  exclusiveMaximumValidationMessage,
  exclusiveMinimumValidationMessage,
  maxItemsValidationMessage,
  maxlengthValidationMessage,
  maxValidationMessage,
  minItemsValidationMessage,
  minlengthValidationMessage,
  minValidationMessage,
  multipleOfValidationMessage,
} from './formly-utils/validation-messages';
import { CplProjectConfigInputComponent } from './cpl-project-config-input/cpl-project-config-input.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule.forChild({
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'null', message: 'should be null' },
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
        { name: 'multipleOf', message: multipleOfValidationMessage },
        { name: 'exclusiveMinimum', message: exclusiveMinimumValidationMessage },
        { name: 'exclusiveMaximum', message: exclusiveMaximumValidationMessage },
        { name: 'minItems', message: minItemsValidationMessage },
        { name: 'maxItems', message: maxItemsValidationMessage },
        { name: 'uniqueItems', message: 'should NOT have duplicate items' },
        { name: 'const', message: constValidationMessage },
      ],
      types: [
        {
          name: 'string',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              appearance: 'outline',
            },
          },
        },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
              appearance: 'outline',
            },
          },
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
              appearance: 'outline',
            },
          },
        },
        {
          name: 'boolean',
          extends: 'checkbox',
        },
        {
          name: 'enum',
          extends: 'select',
          defaultOptions: {
            templateOptions: {
              appearance: 'outline',
            },
          },
        },
        { name: 'null', component: NullTypeComponent },
        { name: 'array', component: ArrayTypeComponent },
        { name: 'object', component: ObjectTypeComponent },
        { name: 'multischema', component: MultiSchemaTypeComponent },
      ],
    }),
  ],
  declarations: [
    CplProjectConfigInputComponent,
    ArrayTypeComponent,
    ObjectTypeComponent,
    MultiSchemaTypeComponent,
    NullTypeComponent,
  ],
  exports: [CplProjectConfigInputComponent],
})
export class CplProjectConfigInputModule {}
