import { Inject, Injectable } from '@angular/core';
import { User } from '../../auth/auth.types';
import { WINDOW_TOKEN } from '../../core/constants/general.constants';
import { IntercomEventType } from './csd-intercom.types';
import { idFromGlobalId } from '../../shared/utils';
import { GLOBAL_USER_INTERCOM_HASH_VARIABLE_NAME } from './csd-intercom.constants';

@Injectable({
  providedIn: 'root',
})
export class CsdIntercomService {
  constructor(@Inject(WINDOW_TOKEN) private window: any) {
    console.log('CsdIntercomService.constructor', window['Intercom']);
  }

  login(user: User) {
    console.log('CsdIntercomService.boot', user);
    this.window[GLOBAL_USER_INTERCOM_HASH_VARIABLE_NAME] = user.intercomHash;
    this.getIntercom()('boot', {
      app_id: user.intercomAppId,
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      user_id: idFromGlobalId(user.id),
      user_hash: user.intercomHash,
      //hide_default_launcher: true,
    });
  }

  getIntercom() {
    const intercomFunc = this.window['Intercom'];
    if (intercomFunc) {
      return intercomFunc;
    } else {
      return () => {
        console.warn('CsdIntercomService: window.Intercom not available!');
      };
    }
  }

  logout() {
    console.log('CsdIntercomService.logout');
    this.getIntercom()('shutdown');
  }

  event(eventType: IntercomEventType, payload: any = {}) {
    console.log('CsdIntercomService.event', eventType, payload);
    try {
      this.getIntercom()('trackEvent', eventType, payload);
    } catch (err) {
      console.error('CsdIntercomService.event', err);
    }
  }

  update(payload: any) {
    this.getIntercom()('update', payload);
  }

  showWidget() {
    this.getIntercom()('show');
  }

  hideWidget() {
    this.getIntercom()('hide');
  }
}
