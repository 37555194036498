<script src="csd-password-reset.component.ts"></script>
<script src="../csd-password-reset-page/csd-password-reset-page.component.ts"></script>
<div class="csd-container">
  <div class="csd-password-reset csd-form-with-img">
    <div class="csd-project-logo csd-project-login">
      <div class="csd-project-container">
        <img src="assets/images/logo_rect_dark.svg" alt="Project logo" />
        <p class="csd-description">{{ 'PROJECT.SHORT_DESCRIPTION' | i18next }}</p>
        <button mat-flat-button color="primary">{{ 'PROJECT.BUTTON_TEXT' | i18next }}</button>
      </div>
      <div class="csd-project-footer">
        <p class="csd-description">{{ 'PROJECT.CAPTION_COPYRIGHT' | i18next }}</p>
      </div>
    </div>
    <div class="csd-form csd-login-form">
      <div class="csd-form-inner">
        <div *ngIf="!resetDone" class="csd-form-step">
          <h1 class="csd-title">{{ 'USER_PASSWORD_RESET.TITLE' | i18next }}</h1>
          <p class="csd-description" [innerHtml]="'USER_PASSWORD_RESET.DESCRIPTION' | i18next"></p>

          <form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()" novalidate>
            <mat-form-field [appearance]="formFieldAppearance">
              <mat-label>{{ 'USER_PASSWORD_RESET.FORM.PASSWORD.LABEL' | i18next }}</mat-label>
              <input
                matInput
                [placeholder]="'USER_PASSWORD_RESET.FORM.PASSWORD.PLACEHOLDER' | i18next"
                [type]="passwordHidden ? 'password' : 'text'"
                formControlName="password"
                autocomplete="new-password"
              />
              <mat-icon matSuffix (click)="passwordHidden = !passwordHidden">
                {{ passwordHidden ? 'visibility_off' : 'visibility' }}</mat-icon
              >
              <mat-hint>{{ 'USER_PASSWORD_RESET.FORM.PASSWORD.HINT' | i18next }}</mat-hint>
              <mat-error
                *ngIf="
                  (passwordForm.dirty || passwordForm.touched) && passwordForm.errors?.required
                "
              >
                {{ 'USER_PASSWORD_RESET.FORM.PASSWORD.ERROR.REQUIRED' | i18next }}
              </mat-error>
              <mat-error
                *ngIf="
                  (passwordForm.dirty || passwordForm.touched) && !passwordForm.errors?.required
                "
              >
                {{ 'USER_PASSWORD_RESET.FORM.PASSWORD.ERROR.INVALID' | i18next }}
              </mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="formFieldAppearance">
              <mat-label>{{
                'USER_PASSWORD_RESET.FORM.CONFIRM_PASSWORD.LABEL' | i18next
              }}</mat-label>
              <input
                matInput
                [placeholder]="'USER_PASSWORD_RESET.FORM.CONFIRM_PASSWORD.PLACEHOLDER' | i18next"
                [type]="passwordHidden ? 'password' : 'text'"
                formControlName="confirmPassword"
              />
              <mat-icon matSuffix (click)="passwordHidden = !passwordHidden">
                {{ passwordHidden ? 'visibility_off' : 'visibility' }}</mat-icon
              >
              <mat-error
                *ngIf="
                  (passwordForm.dirty || passwordForm.touched) && passwordForm.errors?.required
                "
              >
                {{ 'USER_PASSWORD_RESET.FORM.CONFIRM_PASSWORD.ERROR.REQUIRED' | i18next }}
              </mat-error>
              <mat-error
                *ngIf="
                  (passwordForm.dirty || passwordForm.touched) && !passwordForm.errors?.required
                "
              >
                {{ 'USER_PASSWORD_RESET.FORM.CONFIRM_PASSWORD.ERROR.MUST_MATCH' | i18next }}
              </mat-error>
            </mat-form-field>

            <button
              class="csd-password-reset-button"
              type="submit"
              mat-flat-button
              color="primary"
              [disabled]="inProgress"
            >
              <mat-spinner *ngIf="inProgress" class="csd-button-spinner"></mat-spinner>
              {{ 'USER_PASSWORD_RESET.FORM.PASSWORD_RESET_BUTTON' | i18next }}
            </button>
          </form>
        </div>
        <div *ngIf="resetDone" class="csd-success-step">
          <h1 class="csd-title">{{ 'USER_PASSWORD_RESET.SUCCESS_TITLE' | i18next }}</h1>
          <p class="csd-description" [innerHtml]="'USER_PASSWORD_RESET.SUCCESS_INFO' | i18next"></p>
          <a [routerLink]="loginUrl" class="csd-back-to-login" color="primary" mat-flat-button>{{
            'USER_PASSWORD_RESET.BACK_TO_LOGIN_BUTTON' | i18next
          }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
