import { gql } from '@apollo/client';

export const logBiEventMutation = gql`
  mutation logBiEvent($input: LogBiEventMutationInput!) {
    logBiEvent(input: $input) {
      error {
        id
        message
        data
      }
      eventRecord {
        saved
        created
        userId
        event
        value
        metadata
        id
      }
    }
  }
`;
