<div class="cpl-services-toolbar">
  <a href="https://app.cnc.io" class="cpl-active">Console</a>
  <a href="https://git.cnc.io">GIT</a>
  <!--  <a href="https://community.cnc.io">Community</a>-->
</div>

<mat-sidenav-container class="csd-sidenav-container" [hasBackdrop]="false">
  <mat-sidenav
    #drawer
    class="csd-main-sidenav"
    fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="false"
  >
    <router-outlet name="main-sidenav"></router-outlet>
  </mat-sidenav>
  <mat-sidenav-content class="csd-main-container">
    <!-- Toolbar -->
    <router-outlet
      name="main-toolbar"
      (activate)="onToolbarActivate($event)"
      (deactivate)="onToolbarDeactivate($event)"
    ></router-outlet>
    <!-- Main Content -->
    <mat-drawer-container
      [hasBackdrop]="false"
      [ngClass]="{
        'csd-public-page': publicPage,
        'csd-private-page': !publicPage,
        'csd-modal-notification': isModalNotifcation
      }"
    >
      <mat-drawer #mainSidenav position="end" mode="side">
        <csd-main-sidenav></csd-main-sidenav>
      </mat-drawer>
      <mat-drawer-content>
        <router-outlet></router-outlet>
      </mat-drawer-content>
    </mat-drawer-container>
  </mat-sidenav-content>
</mat-sidenav-container>
