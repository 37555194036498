import { CsdDataService } from '../../core/services/csd-data.service';
import { BiEvent, LogBiEventInput } from './csd-bi.types';
// 
import { BaseModel } from '../../core/core.types';
import { map, switchMap, tap } from 'rxjs/operators';
import { logBiEventMutation } from './csd-bi.graphql';
// 
import { CsdAuthQuery } from '../../auth/state/csd-auth.query';
import { Injectable } from '@angular/core';

const ENABLE_DEBUG_BI_LOGGING = false;

@Injectable({
  providedIn: 'root',
})
export class CsdBiService {
  constructor(private csdDataService: CsdDataService, private csdAuthQuery: CsdAuthQuery) {}

  // 
  private _log(message?: any, ...optionalParams: any[]) {
    if (!ENABLE_DEBUG_BI_LOGGING) return;
    console.log(message, ...optionalParams);
  }
  // 

  logEvent(event: BiEvent, metadata: any = {}, value: number = 1): void {
    // Empty implementation, upgrade for real implementation
    // 
    const payload = { event, value, metadata, userId: null } as LogBiEventInput;
    this.csdAuthQuery.getUser$
      .pipe(
        map((user) => (user ? BaseModel.idFromGlobalId(user.id) : null)),
        switchMap((userId) => {
          payload.userId = userId;
          this._log('CsdBiService.logEvent: ', payload);
          return this.csdDataService.mutate$(logBiEventMutation, { input: payload }).pipe(
            tap({
              next: (response) => {
                this._log('CsdBiService.successLogBi: ', response);
              },
              error: (error) => {
                this._log('CsdBiService.failureLogBi: ', error);
              },
            })
          );
        })
      )
      .subscribe();
    // 
  }
}
