<div class="cpl-dashboard-page">
  <mat-card class="cpl-welcome-card" *ngIf="showWelcomeMessage">
    <img class="cpl-avatar" src="/assets/images/karin_icon.png" />
    <div class="cpl-content">
      <!--      <button (click)="closeWelcomeMessage()" class="cpl-close-button" mat-icon-button>-->
      <!--        <mat-icon>close</mat-icon>-->
      <!--      </button>-->

      <h1>Hello {{ user?.firstName }} 👋</h1>

      <p>
        and welcome to the Carrot Seed Console. This is your home base for creating and managing
        your Carrot Seed projects. You can get down to business with your SaaS Product right away.
        We’ve prepared a quick guide so you can kickstart your digital product as smoothly as
        possible. You’ll find it below.
      </p>
      <p></p>

      <p>
        Alternatively you can check out our demo first. There’s a
        <a data-gtm="goto-demo-website" href="https://www.demo-todo-app.cnc.io" target="_blank"
          >demo todo app</a
        >
        waiting for you and you can even check out the
        <span class="cpl-a" data-gtm="goto-demo-source" (click)="goToGit()">demo source code</span>
        to see how things work behind the curtains.
      </p>

      <p>Great to have you on board!</p>

      <p class="cpl-author">Karin from Carrot & company</p>
      <div class="cpl-action-buttons">
        <button
          mat-flat-button
          color="primary"
          data-gtm="goto-project-create"
          [routerLink]="[PATH_CREATOR_CREATE_PROJECT]"
        >
          Create your first project
        </button>
        <button
          (click)="showTutorialDialog()"
          data-gtm="show-tutorial-info"
          mat-stroked-button
          color="primary"
        >
          Check out the tutorial
        </button>
      </div>
    </div>
  </mat-card>

  <div class="cpl-onboarding">
    <h1>Get started with Carrot Seed</h1>

    <mat-accordion displayMode="flat">
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
          <mat-progress-bar
            class="cpl-progress-bar"
            mode="determinate"
            [value]="percentTour()"
          ></mat-progress-bar>
          <mat-panel-title>
            <img class="cpl-icon" src="assets/images/icons/accordion-check.svg" />
            Create your account
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="cpl-col-1 cpl-step-create-account">
          <h1>Create your Account</h1>
          <p>
            You’ve already done that! So there’s really nothing left to do here. But if you want to
            change some of the information you’ve provided you can do that. Check out your account
            settings with the button below.
          </p>
          <div class="cpl-action-buttons">
            <a [routerLink]="['/account']" data-gtm="goto-account" mat-flat-button color="primary"
              >Account Settings</a
            >
          </div>
        </div>
        <img class="cpl-col-2" src="assets/images/onboarding/account.png" />
      </mat-expansion-panel>

      <mat-expansion-panel #createProjectStep>
        <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
          <mat-panel-title>
            <cpl-check-icon
              [checked]="getTourStepValue(tourStepKeyEnum.CREATED_PROJECT)"
              (click)="checkClicked($event, tourStepKeyEnum.CREATED_PROJECT)"
            ></cpl-check-icon>
            Create your project
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="cpl-col-1 cpl-step-create-project">
          <h1>Create your Project</h1>
          <p>
            When you create your project with the button below – or in the projects overview page –
            we create a tailormade git monorepo with the full source code for you. To get only the
            features you need you can configure the creation of your project. But don’t worry: You
            can change this easily if your needs shift at any future date.
          </p>
          <div class="cpl-action-buttons">
            <button
              mat-flat-button
              color="primary"
              data-gtm="goto-project-create"
              [routerLink]="[PATH_CREATOR_CREATE_PROJECT]"
            >
              Create my Project
            </button>
            <button
              [routerLink]="['/creator', 'projects']"
              data-gtm="goto-project-list"
              mat-stroked-button
              color="primary"
            >
              Projects Overview
            </button>
          </div>
          <button
            (click)="checkClicked($event, tourStepKeyEnum.CREATED_PROJECT)"
            data-gtm="mark-as-done"
            mat-button
            class="cpl-mark-done"
          >
            {{
              getTourStepValue(tourStepKeyEnum.CREATED_PROJECT) ? 'Mark as undone' : 'Mark as done'
            }}
          </button>
        </div>
        <img class="cpl-col-2" src="assets/images/onboarding/project-list.png" />
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
          <mat-panel-title>
            <cpl-check-icon
              [checked]="getTourStepValue(tourStepKeyEnum.COMPLETED_TUTORIAL)"
              (click)="checkClicked($event, tourStepKeyEnum.COMPLETED_TUTORIAL)"
            ></cpl-check-icon>
            Complete the Tutorial
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="cpl-col-1 cpl-step-tutorial">
          <h1>Complete the Tutorial</h1>
          <p>
            We’re not throwing you in the deep end. To give you a good overview of Carrot Seed,
            we’ve added a tutorial guide to your git repo. With this you can create a fully fledged
            todo app within roughly 2 hours. You can check out the result here.
          </p>
          <p>
            Within the tutorial you get to know a lot of the parts and concepts of Carrot Seed and
            the tools at your disposal. This way you can start implementing your business logic as
            fast as possible. And if you’re eager to get started with your own project you can do
            that and use the tutorial as a guideline.
          </p>
          <div class="cpl-action-buttons">
            <button
              (click)="showTutorialDialog()"
              data-gtm="show-tutorial-info"
              mat-flat-button
              color="primary"
            >
              Start Tutorial
            </button>
            <button
              (click)="toggleTourStep(tourStepKeyEnum.COMPLETED_TUTORIAL)"
              data-gtm="mark-tutorial-step-done"
              mat-stroked-button
              color="primary"
            >
              {{
                getTourStepValue(tourStepKeyEnum.COMPLETED_TUTORIAL)
                  ? 'Mark as undone'
                  : 'Mark as done'
              }}
            </button>
          </div>
        </div>
        <img class="cpl-col-2" src="assets/images/onboarding/tutorial.png" />
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
          <mat-panel-title>
            <cpl-check-icon
              [checked]="getTourStepValue(tourStepKeyEnum.GOT_HELP)"
              (click)="checkClicked($event, tourStepKeyEnum.GOT_HELP)"
            ></cpl-check-icon>
            Get Help
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="cpl-col-1 cpl-step-get-help">
          <h1>Get Help</h1>
          <p>
            If you’re stuck, find a bug or just want to tell us how Carrot Seed is working for you,
            please let us know. We’re happy to help. If you want, there are additional support and
            training packages for the code, design and dev-ops of your digital product.
          </p>

          <!--          <p>-->
          <!--            If you’re stuck, find a bug or just want to tell us how Carrot Seed is working for you,-->
          <!--            we’ve got the right place for you. In our community you’ll find support, updates and-->
          <!--            likeminded people to get the most out of your Carrot Seed experience.-->
          <!--          </p>-->
          <!--          <p>-->
          <!--            If that’s not your thing you can write to us any time. We’re happy to help. If you want,-->
          <!--            there are additional support and training packages for the code, design and dev-ops of-->
          <!--            your digital product.-->
          <!--          </p>-->
          <div class="cpl-action-buttons">
            <!--            <a href="https://community.cnc.io/" target="_blank" mat-flat-button color="primary"-->
            <!--              >Join the discussion</a-->
            <!--            >-->
            <a
              href="mailto:seed@cnc.io"
              data-gtm="mailto-us"
              target="_blank"
              mat-flat-button
              color="primary"
              >seed@cnc.io</a
            >
            <a
              href="https://www.cnc.io/en/seed#addons"
              target="_blank"
              data-gtm="goto-website-addons"
              mat-stroked-button
              color="primary"
            >
              Add-Ons</a
            >
          </div>
          <button
            (click)="checkClicked($event, tourStepKeyEnum.GOT_HELP)"
            data-gtm="mark-as-done"
            mat-button
            class="cpl-mark-done"
          >
            {{ getTourStepValue(tourStepKeyEnum.GOT_HELP) ? 'Mark as undone' : 'Mark as done' }}
          </button>
        </div>
        <img class="cpl-col-2" src="assets/images/onboarding/contact.png" />
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
          <mat-panel-title>
            <cpl-check-icon
              [checked]="getTourStepValue(tourStepKeyEnum.MANAGE_PLANS)"
              (click)="checkClicked($event, tourStepKeyEnum.MANAGE_PLANS)"
            ></cpl-check-icon>
            Manage your plans
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="cpl-col-1 cpl-step-manage-plans">
          <h1>Manage your Plans</h1>
          <p>
            You can easily change the plan of any of your projects without losing anything. Your
            project configuration and all the work you’ve put into it will remain unchanged when
            you, for example, upgrade from the free trial to the basic plan. Just click “change
            plan” on the project of your choosing in the projects overview page.
          </p>
          <div class="cpl-action-buttons">
            <button
              [routerLink]="['/creator', 'projects']"
              data-gtm="goto-project-list"
              mat-flat-button
              color="primary"
            >
              My Projects
            </button>
            <a
              href="https://www.cnc.io/en/seed#pricing"
              target="_blank"
              mat-stroked-button
              color="primary"
              data-gtm="goto-seed-website-pricing"
              >Plans</a
            >
          </div>
          <button
            (click)="checkClicked($event, tourStepKeyEnum.MANAGE_PLANS)"
            data-gtm="mark-as-done"
            mat-button
            class="cpl-mark-done"
          >
            {{ getTourStepValue(tourStepKeyEnum.MANAGE_PLANS) ? 'Mark as undone' : 'Mark as done' }}
          </button>
        </div>
        <img class="cpl-col-2" src="assets/images/onboarding/manage-plans.png" />
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
