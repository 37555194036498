import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cpl-tutorial-dialog',
  templateUrl: './cpl-tutorial-dialog.component.html',
  styleUrls: ['./cpl-tutorial-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CplTutorialDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CplTutorialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    //this.dialogRef.addPanelClass()
  }

  onOkClick(): void {
    this.dialogRef.close();
  }
}
