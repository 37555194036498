<div class="cpl-tutorial-dialog">
  <h1 mat-dialog-title>Start Tutorial</h1>
  <div mat-dialog-content>
    <h2>1) Create a project</h2>
    <p>First make sure you <b>created a project</b> (eg. Trial project).</p>

    <h2>2) Clone Git Repo</h2>
    <p>
      Then follow the instructions and locally <b>checkout the git repository</b> on your
      development machine
    </p>

    <h2>4) Quickstart Guide</h2>
    <p>Follow the <b>quickstart guide</b> in the <span class="cpl-code">README</span> file</p>

    <h2>5) Start Tutorial 🎉</h2>
    <p>
      After the project is up and running, open the browser on
      <span class="cpl-code">https://docs.&lt;yourprojectdomain&gt;.local/</span> and then click on
      "Todo App Tutorial".
    </p>
    <h2>👉 Alternatively</h2>
    <p>
      You can also directly look a the tutorials source code file which is located in
      <span class="cpl-code">&lt;yourprojectdir&gt;/docs/docs/todo_app_tutorial.rst</span>.
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="onOkClick()" cdkFocusInitial>Ok</button>
  </div>
</div>
